'use client';
import Link from 'next/link';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';

import { appointmentUrl, testsCategoryUrl } from 'src/services/route-utils';
import CarouselItem from './Carouseltem';
import Carousel from 'src/components/Carousel';
import { CarouselItem as CarouselItemModel } from 'src/services/keystone-types';

type Props = {
    carouselItems: CarouselItemModel[];
};

export default function HeroSection(props: Props) {
    const links = [
        { label: 'Tipuri de analize și prețuri', href: testsCategoryUrl(null) },
        { label: 'Fă-ți o programare', href: appointmentUrl() },
        { label: 'Cum te pregătești pentru analize?', href: 'blog/ghid-de-recoltare/cum-te-pregatesti-pentru-analize' },
    ];

    const carouselItem = (item: CarouselItemModel, active: boolean, index: number, shouldLoadImage: boolean) =>
        !!item && (
            <CarouselItem
                align={item.align}
                dark={item.dark}
                imageSrc={item.image?.image || ''}
                variant={item.variant}
                title={item.title}
                description={item.description}
                action={item.action}
                actionTitle={item.action_title}
                actionUrl={item.action_url}
                active={active}
                priority={index === 0}
                shouldLoadImage={shouldLoadImage}
                renderHeaderAs={index === 0 ? 'h1' : 'h2'}
            />
        );

    return (
        <section className="md:container md:mx-auto">
            <div className="h-[300px] md:h-[452px] xl:h-[550px]">
                {props.carouselItems.length > 1 ? (
                    <Carousel
                        duration={7500}
                        items={props.carouselItems}
                        keyForItem={(item) => item.id}
                        contentForItem={(item, active, index, shouldLoadImage) =>
                            carouselItem(item, active, index, shouldLoadImage)
                        }
                    />
                ) : (
                    carouselItem(props.carouselItems[0], true, 0, false)
                )}
            </div>
            <ul className="text-primary flex flex-col md:flex-row">
                {links.map((link) => (
                    <li
                        key={link.href}
                        className="border-medium-green relative w-full border-b last:border-r-0 md:w-1/3 md:border-r">
                        <Link
                            className="hover:bg-light-green flex h-full items-center p-4 pr-24 hover:underline md:py-6"
                            href={link.href}>
                            {link.label}
                        </Link>
                        <HiOutlineArrowNarrowRight
                            aria-hidden
                            className="pointer-events-none absolute right-4 top-1/2 -mt-4 text-4xl"
                        />
                    </li>
                ))}
            </ul>
        </section>
    );
}
