'use client';

import Logo from '../../public/images/logo-watermark.svg';

type Props = {
    className?: string;
};

export default function LogoWatermark({ className }: Props) {
    return <Logo className={className} />;
}
