'use client';
import Link from 'next/link';

import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import LogoWatermark from 'src/components/LogoWatermark';

import ScrollWithCarets from 'src/components/ScrollWithCarets';
import { labLandingUrl, testsCategoryUrl, thirtyYearsUrl } from 'src/services/route-utils';

type Props = {};

export default function HighlightsSection(props: Props) {
    return (
        <section className="md:my-10">
            <ScrollWithCarets>
                <div className="container relative mx-auto h-52 overflow-visible lg:h-[640px]">
                    <div className="absolute flex h-full flex-nowrap lg:static lg:grid lg:grid-cols-8">
                        <div
                            data-carousel-item
                            className="bg-light-grey relative col-span-4 row-span-2 w-80 snap-start overflow-hidden p-4 py-10 md:p-12 lg:w-auto lg:py-24">
                            <LogoWatermark className="text-primary absolute bottom-0 right-0 origin-bottom-right scale-50 opacity-20 lg:transform-none" />
                            <h2 className="w-2/3 text-4xl lg:text-6xl">De ce să alegi Bioclinica?</h2>
                        </div>

                        <div data-carousel-item className="relative col-span-4 w-80 snap-center lg:w-auto">
                            <Link className="group block h-full p-8 py-10 md:p-12" href={testsCategoryUrl(null)}>
                                <h3 className="mb-4 text-4xl decoration-1 underline-offset-4 group-hover:underline">
                                    Analize diverse, rezultate rapide
                                </h3>
                                <p>Peste 2.700 investigații de laborator</p>
                                <HiOutlineArrowNarrowRight
                                    aria-hidden
                                    className="pointer-events-none absolute bottom-4 right-4 hidden text-4xl lg:block"
                                />
                            </Link>
                        </div>

                        <div
                            data-carousel-item
                            className="bg-dark-green relative col-span-2 w-80 snap-center text-white lg:w-auto">
                            <Link className="group block h-full p-8 py-10 md:p-12" href={thirtyYearsUrl()}>
                                <h3 className="mb-4 text-4xl decoration-1 underline-offset-4 group-hover:underline">
                                    Peste 30 ani de experiență
                                </h3>
                                <p>Oamenii din spatele analizelor tale</p>
                                <HiOutlineArrowNarrowRight
                                    aria-hidden
                                    className="pointer-events-none absolute bottom-4 right-4 hidden text-4xl lg:block"
                                />
                            </Link>
                        </div>

                        <div
                            data-carousel-item
                            className="bg-light-green text-primary relative col-span-2 w-80 snap-end lg:w-auto">
                            <Link className="group block h-full p-8 py-10 md:p-12" href={labLandingUrl()}>
                                <h3 className="mb-4 text-4xl decoration-1 underline-offset-4 group-hover:underline">
                                    Aparatură modernă
                                </h3>
                                <p>Află mai multe</p>
                                <HiOutlineArrowNarrowRight
                                    aria-hidden
                                    className="pointer-events-none absolute bottom-4 right-4 hidden text-4xl lg:block"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </ScrollWithCarets>
        </section>
    );
}
