'use client';

import { useEffect, useState, createRef, useRef } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

type Props = {
    caretsClassName?: string;
} & React.PropsWithChildren;

const ScrollWithCarets: React.FunctionComponent<Props> = ({ caretsClassName, children }) => {
    const [displayLeftCaret, setDisplayLeftCaret] = useState(false);
    const [displayRightCaret, setDisplayRightCaret] = useState(false);
    const scrollDivRef = createRef<HTMLDivElement>();
    const items = useRef<HTMLElement[]>([]);

    useEffect(() => {
        const div = scrollDivRef.current;
        if (!div) return;

        items.current = [...div.querySelectorAll<HTMLElement>('[data-carousel-item]')];

        const computeCarets = () => {
            setDisplayLeftCaret(div.scrollLeft > 0);
            setDisplayRightCaret(Math.ceil(div.scrollLeft + div.clientWidth) < div.scrollWidth);
        };

        window.addEventListener('resize', computeCarets);
        div.addEventListener('scroll', computeCarets);
        computeCarets();

        return () => {
            window.removeEventListener('resize', computeCarets);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getVisibleIndexes = (): [number, number] => {
        const div = scrollDivRef.current;
        if (!div) return [0, 0];

        const firstVisibleIndex = Math.max(
            items.current.findIndex((i: any) => i.offsetLeft >= div.scrollLeft),
            0,
        );
        const lastVisibleIndex = Math.min(
            Math.max(
                items.current.findLastIndex((i) => i.offsetLeft + i.clientWidth < div.scrollLeft + div.clientWidth),
                0,
            ),
            items.current.length - 1,
        );

        return [firstVisibleIndex, lastVisibleIndex];
    };

    const scrollPage = (direction: 'l' | 'r') => {
        const div = scrollDivRef.current;
        if (!div || !items.current.length) return;

        const [firstVisibleIndex, lastVisibleIndex] = getVisibleIndexes();

        const scrollToElementIndex = items.current.findIndex((i) =>
            direction === 'r'
                ? i.offsetLeft >=
                  items.current[lastVisibleIndex].offsetLeft + items.current[lastVisibleIndex].clientWidth
                : i.offsetLeft >= items.current[firstVisibleIndex].offsetLeft - div.clientWidth,
        );
        div.scrollTo({
            left:
                items.current[scrollToElementIndex !== -1 ? scrollToElementIndex : items.current.length - 1]
                    .offsetLeft - items.current[0].offsetLeft,
            behavior: 'smooth',
        });
    };

    const commonClassNames = `bg-primary absolute${
        caretsClassName ? '' : ' top-1/2'
    } -mt-10 cursor-pointer p-2 text-4xl text-white drop-shadow-lg hover:bg-primary-hover lg:-mt-7`;

    return (
        <div className="relative">
            <div className="scrollbar-hide snap-x snap-mandatory overflow-scroll" ref={scrollDivRef}>
                {children}
            </div>
            {displayLeftCaret && (
                <div
                    role="button"
                    aria-label="Înapoi"
                    className={`${commonClassNames} left-0 rounded-r-full ${caretsClassName || ''}`}
                    onClick={() => scrollPage('l')}>
                    <HiChevronLeft />
                </div>
            )}
            {displayRightCaret && (
                <div
                    role="button"
                    aria-label="Înainte"
                    className={`${commonClassNames} right-0 rounded-l-full ${caretsClassName || ''}`}
                    onClick={() => scrollPage('r')}>
                    <HiChevronRight />
                </div>
            )}
        </div>
    );
};

export default ScrollWithCarets;
