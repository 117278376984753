const common = require('../config/common.json');

type IConfig = {
    SITE_HOST: string;
    KEYSTONE_HOST: string;

    MAX_FILE_SIZE_BYTES: number;
    API_KEY: string;
    PAGE_SIZE: number;
    COOKIE_SECRET: string;

    USER_ACTIVATION_SECRET: string;
    SEND_EMAILS: string;
    EMAIL_CONTACT_ADDRESS: string;
    EMAIL_SENDER_ADDRESS: string;
    EMAIL_REPLY_TO: string;
    EMAILS_SEND_WHITELIST: string[];
    BLOCK_EXTERNAL_RESOURCES_IP_LIST: string;
    MAINTENANCE_MODE: string;

    DELETE_OLD_RESULT_YEARS: number;
    CLEAN_OLD_APPOINTMENTS_WEEKS: number;

    S3_BUCKET: string;
    S3_REGION: string;
    S3_ENDPOINT: string;
    S3_KEY: string;
    S3_SECRET: string;

    AWS_S3_BUCKET: string;
    AWS_S3_REGION: string;
    AWS_S3_ENDPOINT: string;
    AWS_S3_KEY: string;
    AWS_S3_SECRET: string;

    AWS_ASSETS_S3_BUCKET: string;
    AWS_ASSETS_S3_REGION: string;
    AWS_ASSETS_S3_ENDPOINT: string;
    AWS_ASSETS_S3_KEY: string;
    AWS_ASSETS_S3_SECRET: string;
    ASSETS_CDN_URL: string;

    AWS_SES_API_KEY: string;
    AWS_SES_SECRET_KEY: string;
    AWS_SES_REGION: string;
    SES_CONFIGURATION_SET: string;

    DATABASE_URL: string;
    PRISMA_DATABASE_URL: string;
    DATABASE_AES_ENCRYPT_KEY: string;

    SESSION_REDIS_URL: string;
    SESSION_REDIS_SECRET: string;

    SENTRY_ENV: string;
    GOOGLE_ANALYTICS_ID: string;
    FACEBOOK_APP_ID: string;
    RECAPTCHA_KEY_ID: string;
    RECAPTCHA_PROJECT_ID: string;
    RECAPTCHA_PRIVATE_KEY: string;
    MAPBOX_TOKEN: string;
    MAPBOX_PUBLIC_TOKEN: string;
    WITHOUT_ASSETS: string;
    TZ: string;
    DISALLOW_ROBOTS: string;

    UPSTASH_TOKEN: string;
    UPSTASH_URL: string;
};

const config: IConfig = Object.assign(common, process.env);

export default config;
